import { NgModule, isDevMode, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import Bugsnag from '@bugsnag/js'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../../../environments/environment';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import { provideAuth, getAuth, connectAuthEmulator, indexedDBLocalPersistence, initializeAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { ServiceWorkerModule } from '@angular/service-worker'
import { AuthService, SubscriberService, UserPreferencesService, UserService } from 'common';
import { RouteReuseStrategy } from '@angular/router';
import { ROLE_CONTEXT, APP_NAME, ALLOW_REGISTRATION, ENVIRONMENT } from 'common';
import { AppUpdateService } from 'projects/common/src/public-api';
import { Capacitor } from '@capacitor/core';
import { APP_VERSION } from 'projects/environments/version';
import { registerLocaleData } from '@angular/common';
import localeFi from '@angular/common/locales/fi';

registerLocaleData(localeFi, 'fi-FI');

if (environment.production === true) {
  Bugsnag.start({ apiKey: '5128fdd704c105e7106a581a2985b049', appVersion: APP_VERSION })
  BugsnagPerformance.start({ apiKey: '5128fdd704c105e7106a581a2985b049' })
}

export function errorHandlerFactory() {
  if (environment.production === true) {
    return new BugsnagErrorHandler();
  } else {
    return new ErrorHandler();
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          /*    persistence: indexedDBLocalPersistence, */
        });
      } else {
        const auth = getAuth();
        if (environment.firebase_hosting === false) {
          connectAuthEmulator(auth, 'http://localhost:9099');
        }
        return auth;
      }
    }),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
    IonicModule.forRoot({
      swipeBackEnabled: false
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })],
  providers: [
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    UserPreferencesService,
    AuthService,
    { provide: APP_NAME, useValue: 'Logbook' },
    { provide: ALLOW_REGISTRATION, useValue: true },
    { provide: ENVIRONMENT, useValue: environment },
    {
      provide: ROLE_CONTEXT, useValue: ['free', 'super-admin', 'admin', 'user', 'single']
    },
    AppUpdateService,
    AuthService,
    UserService,
    SubscriberService],
  bootstrap: [AppComponent],
})
export class AppModule { }